import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BroadcastService, ScreenSizeEvent } from "root/services";
import { Subscription } from "rxjs";

@Component({
  selector: 'mibp-horizontal-scroll-container',
  templateUrl: './horizontal-scroll-container.component.html',
  styleUrls: ['./horizontal-scroll-container.component.scss']
})
export class MibpHorizontalScrollContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  screenSizeSub?: Subscription;
  hasScroll: boolean;
  startupTimer?: number;
  arrowTimer?: number;
  protected hasFinePointer = false;
  protected showCustomScrollElements = true;

  /**
   * When true and a fine pointer is not detected, then the custom scroll elements will be hidden
   * and user should just rely on default scroll functionality instead
   * Used for tabs
   */
  @Input() hideScrollbarForTouchDevices = false;
  @Input() showBottomBorder = false;
  @Input() addBottomMargin = false;
  @Input() smallScrollButtons = false;

  @ViewChild('scrollableElement') scrollableElement: ElementRef<HTMLDivElement>;
  @ViewChild('containerElement') containerElement: ElementRef<HTMLDivElement>;

  constructor(private broadcast: BroadcastService) {}

  ngOnInit(): void {
    this.checkIfCustomElementsShouldBeUsed();
    this.screenSizeSub = this.broadcast.screenSize.subscribe(() => this.onScreenChange());
    this.arrowTimer = window.setInterval(() => this.onScreenChange(), 100);
  }

  private checkIfCustomElementsShouldBeUsed(): void {
    if (!this.hideScrollbarForTouchDevices) {
      this.showCustomScrollElements = true;
    } else {
      if (window.matchMedia('(pointer: fine)').matches) {
        this.showCustomScrollElements = true;
      } else {
        this.showCustomScrollElements = false;
      }
    }
  }

  onScreenChange(): void {
    this.checkIfCustomElementsShouldBeUsed();
    if (this.scrollableElement && this.containerElement) {
      const a = this.containerElement.nativeElement.getBoundingClientRect().width;
      const b = this.scrollableElement.nativeElement.scrollWidth;

      this.hasScroll = (b-2) > a;
    } else {
      setTimeout( () => {
        this.onScreenChange();
      }, 100);
    }
  }

  ngAfterViewInit(): void {
    this.onScreenChange();

    // On initialization, make sure scroll-check is run regularly for some time to adjust to any content being rendered
    this.startupTimer = window.setInterval(() => this.onScreenChange(), 150);
    setTimeout(() => clearInterval(this.startupTimer) , 5000);
  }

  onScroll(e: Event, add: number): void {
    e.preventDefault();
    e.stopPropagation();
    const newPosition = this.scrollableElement.nativeElement.scrollLeft + add;
    this.scrollTo(newPosition);
  }

  ngOnDestroy(): void {
    this.screenSizeSub?.unsubscribe();
    setTimeout(() => clearInterval(this.arrowTimer) , 5000);
  }

  private easeInOutQuad(currentTime, startValue, changeInValue, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return changeInValue / 2 * currentTime * currentTime + startValue;
    }
    currentTime--;
    return -changeInValue / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
  }

  public scrollTo(to: number, duration = 150): void {
    const element = this.scrollableElement.nativeElement as HTMLDivElement;
    const start = element.scrollLeft,
      change = to - start,
      increment = 20,
      easeInOutQuad = this.easeInOutQuad;
    let currentTime = 0;

    const animateScroll = function () {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

}
