import { MibpLogger } from './../logservice/mibplogger.class';
import { LogService } from './../logservice/log.service';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MibpDOMHelperService {

  log: MibpLogger;
  constructor(logger: LogService) {
    this.log = logger.withPrefix('dom-helper');
  }

  public isElementVisible(element: HTMLElement): boolean {
    return !!element.offsetParent;
  }

  public async waitForElement(selector: string, timeoutMs = 5000, mustBeVisible = false, container: HTMLElement = null): Promise<HTMLElement> {
    this.log.debug('Waiting for element', selector);
    return new Promise<HTMLElement>((resolve, reject) => {

      container = container || document.documentElement;

      const elements = container.querySelectorAll(selector);
      let element: HTMLElement;

      for (let i =  0; i < elements.length; i++) {
        if (!mustBeVisible || this.isElementVisible(elements[i] as HTMLElement)) {
          element = elements[i] as HTMLElement;
          break;
        }
      }

      if (element != null) {
        this.log.debug('Found element', element);
        return resolve(element as HTMLElement);
      }

      const tick = () => {

        element = document.querySelector(selector);

        if (element != null) {
          return resolve(element as HTMLElement);
        }

        // Element not found. Start timer again.
        const currentTime = new Date();
        if (timeoutMs && currentTime.getTime() - started.getTime() >= timeoutMs) {
          this.log.debug('Element was not found in DOM', selector);
          reject();
        } else {
          setTimeout(tick, 150);
        }
      };

      // Start timer to find element
      const started = new Date();
      setTimeout(tick, 150);

    });

  }

  /**
   * Is resolved when element does not exist in DOM
   * Is rejected when the optional timeout has expired
   * @param selector the querySelector for the element
   * @param timeoutMs The optional timeout to wait
   */
  public async waitForElementGone(selector: string, timeoutMs: number = null): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      let element = document.querySelector(selector);

      if (element == null) {
        return resolve();
      }

      const tick = () => {

        element = document.querySelector(selector);

        if (element == null) {
          return resolve();
        }

        // Element was found. Start timer again.
        const currentTime = new Date();
        if (timeoutMs && currentTime.getTime() - started.getTime() >= timeoutMs) {
          reject();
        } else {
          setTimeout(tick, 150);
        }
      };

      // Start timer to find element
      const started = new Date();
      setTimeout(tick, 150);

    });

  }

}
